import supportApi from '@rosfines/vue-common/common/api/supportApi';
import finesApi from '@rosfines/vue-common/common/api/finesApi';
import { TICKET_STATUSES } from '@/constants/support'
import { regular, vip } from './solutions/root';
import { solution_data } from './solutions/solution_data';

let resolveWaitLoadingTickets;
let rejectWaitLoadingTickets;
let ticketsLoad;
function reInitTicketsLoad() {
  resolveWaitLoadingTickets && resolveWaitLoadingTickets();
  ticketsLoad = new Promise((resolve, reject) => {
    resolveWaitLoadingTickets = resolve;
    rejectWaitLoadingTickets = reject;
  });
}
reInitTicketsLoad();

const support = {
  namespaced: true,
  state() {
    return {
      ticketsList: [],
      isLoaded: false,
      contactEmail: '',
      contactPhone: '',
      phones: [],
      emails: [],
      profilePhone: '',
      profileEmail: '',
      restrictions: {
        allowCreateTicket: false,
        allowReopenTicket: false,
        hasHiddenInn: false,
        isCjmEnabled: true,
        isWebUser: false,
      },
      solutions: {
        productTitles: {
          fine: 'Штрафы',
          ins: 'Страхование',
          tax: 'Налоги',
          common: 'Общие вопросы',
          marketing: 'Акции и предложения',
        },
        regular,
        vip,
        data: solution_data,
        history: [],
      }
    }
  },
  getters: {
    ticketById: (state) => (ticketId) => {
      const ticket = state.ticketsList.filter((ticket) => ticket.id === ticketId);
      return ticket && ticket[0] || {};
    },
    contactsData: (state) => () => {
      return {
        contactEmail: state.contactEmail,
        contactPhone: state.contactPhone,
        profilePhone: state.profilePhone,
        profileEmail: state.profileEmail,
        phones: state.phones,
        emails: state.emails,
      };
    },
    isAllowCreateTicket: ({ restrictions: { allowCreateTicket } }) => allowCreateTicket,
    isAllowReopenTicket: ({ restrictions: { allowReopenTicket } }) => allowReopenTicket,
    hasHiddenInn: ({ restrictions: { hasHiddenInn } }) => hasHiddenInn,
    isCjmEnabled: ({ restrictions: { isCjmEnabled } }) => isCjmEnabled,
    isWebUser: ({ restrictions: { isWebUser } }) => isWebUser,
    getUserSolutions: function ({ solutions: { regular, vip } }, { isAllowCreateTicket, isAllowReopenTicket }) {
      return (isAllowCreateTicket && isAllowReopenTicket) ? vip : regular;
    },
    getProductSolutions: ({ solutions: { history } }, { getUserSolutions }) => (product) => history.reduce((p, c) => p?.[c], getUserSolutions[product]),
    getProductSolutionTitle({ solutions: { history, productTitles } }, { getUserSolutions, isSolutionsHistoryEmpty }) {
      return function (product) {
        if (isSolutionsHistoryEmpty) {
          return productTitles[product];
        }
        const [title,] = history.slice(0, -1).reduce((p, c) => p?.[c], getUserSolutions[product]);
        return typeof title === 'string' ? title : '';
      }
    },
    isSolutionsHistoryEmpty: ({ solutions: { history } }) => !history.length,
    getUserAuthString: function () {
      const auth = finesApi.getRequestParams();
      return `userId=${auth.userId}&session=${auth.session}&rand=${auth.rand}`;
    },
    faqUrl: function (state, { getUserAuthString }) {
      const referer = encodeURIComponent(`${process.env.VUE_APP_HOST}/?${getUserAuthString}`);
      return `${process.env.VUE_APP_FAQ_HOST}/?${getUserAuthString}&referer=${referer}`;
    },
    faqUrlAnswer: function (state, { getUserAuthString }) {
      return function (slug) {
        const referer = encodeURIComponent(`${process.env.VUE_APP_HOST}/?${getUserAuthString}`);
        return `${process.env.VUE_APP_FAQ_HOST}/question/${slug}/?${getUserAuthString}&referer=${referer}`;
      }
    },
    faqQuestionSearch: function (state, { getUserAuthString }) {
      return function (query) {
        const referer = encodeURIComponent(`${process.env.VUE_APP_HOST}/?${getUserAuthString}`);
        return `${process.env.VUE_APP_FAQ_HOST}/search/${query}?${getUserAuthString}&referer=${referer}`;
      }
    },
    insNotificationsUrl(state, { getUserAuthString }) {
      const notifications = encodeURIComponent(`${process.env.VUE_APP_INS_HOST}/notifications?${getUserAuthString}`);
      return `rosfines://app/webview?url=${notifications}`;
    },
    solutionsSearch({ solutions: { data } }, { isVipUser }) {
      return function (query) {
        return Object.fromEntries(Object.entries(data).map(([product, solutions]) => {
          return [
            product,
            Object.fromEntries(Object.entries(solutions).filter(([, solution]) => {
              const { title = '', vip_access = true, regular_access = true } = solution;
              return (title.trim() !== '') && (isVipUser ? vip_access : regular_access) && title.trim().toLowerCase().includes(query.trim().toLowerCase());
            }))
          ];
        }).filter(([, solutions]) => Object.keys(solutions).length !== 0));
      };
    },
    getProductSolutionById: ({ solutions: { data } }) => ({ product, solution_id }) => data[product][solution_id],
    getFineSolutionById: (state, { getProductSolutionById }) => (id) => getProductSolutionById({product: 'fine', solution_id: id}),
    getTaxSolutionById: (state, { getProductSolutionById }) => (id) => getProductSolutionById({product: 'tax', solution_id: id}),
    getInsSolutionById: (state, { getProductSolutionById }) => (id) => getProductSolutionById({product: 'ins', solution_id: id}),
    getCommonSolutionById: (state, { getProductSolutionById }) => (id) => getProductSolutionById({ product: 'common', solution_id: id }),
    getMarketingSolutionById: (state, { getProductSolutionById }) => (id) => getProductSolutionById({product: 'marketing', solution_id: id}),
    isVipUser: function (state, { isAllowCreateTicket, isAllowReopenTicket, isCjmEnabled }) {
      if (false === isCjmEnabled) {
        return true;
      }
      return isAllowCreateTicket && isAllowReopenTicket;
    },
    getUserId: () => finesApi.getRequestParams().userId / 1,
    getSolutionsHistoryLength: ({ solutions: { history } }) => history.length,
    getProductSolutionHistoryTitles: function ({ solutions: { history } }, { getUserSolutions }) {
      return function (product) {
        let solutions = getUserSolutions[product];
        const titles = [];
        history.forEach(
          (index) => {
            const title = solutions[index][0];
            if (typeof title === 'string') {
              titles.push(title);
            }
            solutions = solutions[index];
          }
        );

        return titles;
      };
    },
    getProductTitle: ({ solutions: { productTitles } }) => (product) => productTitles[product],
  },
  mutations: {
    updateTicketByData(state, data) {
      const idx = state.ticketsList.findIndex(({ id }) => {
        return data.id === id;
      });
      if (idx !== -1) {
        state.ticketsList[idx] = data;
      }
    },
    updateRestrictions(state, { allowCreateTicket, allowReopenTicket, hasHiddenInn, isCjmEnabled, isWebUser }) {
      state.restrictions = { allowCreateTicket, allowReopenTicket, hasHiddenInn, isCjmEnabled, isWebUser };
    },
    setTicketsList(state, value) {
      value.sort((a, b) => (
        (a.hasNewNotifications && !b.hasNewNotifications)
          ? -1
          : (!a.hasNewNotifications && b.hasNewNotifications)
            ? 1
            : (a.status === TICKET_STATUSES.WAITING && b.status !== TICKET_STATUSES.WAITING)
              ? -1
              : (a.status !== TICKET_STATUSES.WAITING && b.status === TICKET_STATUSES.WAITING)
                ? 1
                : (a.status === TICKET_STATUSES.OPEN && b.status !== TICKET_STATUSES.OPEN)
                  ? -1
                  : (a.status !== TICKET_STATUSES.OPEN && b.status === TICKET_STATUSES.OPEN)
                    ? 1
                    : (a.updatedTime > b.updatedTime)
                      ? -1
                      : ((a.updatedTime < b.updatedTime)
                        ? 1
                        : 0
                      )
      )
      );
      state.isLoaded = true;
      state.ticketsList = value;
    },
    markAsRead(state, ticketId) {
      const ticket = state.ticketsList.filter((ticket) => ticket.id === ticketId);
      if (ticket && ticket[0]) {
        ticket[0].hasNewNotifications = false;
      }
    },

    setTicketContactData(state, data) {
      state.contactPhone = data.phone ? data.phone : '';
      state.contactEmail = data.email ? data.email : '';
    },

    setTicketInfoFields(state, { ticketId, fields }) {
      const ticket = state.ticketsList.filter((ticket) => ticket.id === ticketId);
      if (ticket && ticket[0]) {
        for (let fieldName in fields) {
          ticket[0][fieldName] = fields[fieldName];
        }
      }
    },
    pushToSolutionsHistory(state, index) {
      state.solutions.history = [...state.solutions.history, ...[index, 1]];
    },
    backInSolutionsHistory(state) {
      const sliced = state.solutions.history.slice(0, -2);
      state.solutions.history = sliced;
    },
    resetSolutionsHistory(state) {
      state.solutions.history = [];
    }
  },
  actions: {
    async waitLoadingTicketsList() {
      return ticketsLoad;
    },
    async loadTicketsList({ commit }) {
      const response = await supportApi.get("/tickets").catch(async () => {
        throw new Error('failed loading tickets list');
      });
      if (supportApi.isSuccess(response)) {
        commit("setTicketsList", response.data.items);
        resolveWaitLoadingTickets();
      } else {
        rejectWaitLoadingTickets();
        throw new Error('failed parsing tickets list');
      }
      return response;
    },
    async markTicketAsRead({ dispatch }, ticketId) {
      await supportApi.post(`/ticket/${ticketId}/read-messages`).catch(async () => {
        throw new Error('failed loading tickets list');
      });
      dispatch("app/loadNotificationsCount", undefined, { root: true })
    },
    async loadTicketData({ commit }, ticketId) {
      const response = await supportApi.get(`ticket/${ticketId}`).catch(async () => {
        throw new Error('failed loading user contacts');
      });
      if (supportApi.isSuccess(response)) {
        commit("setTicketContactData", response.data);
      }
      return response;
    },
    watchTicket({ commit }, { ticketId, ttl_sec }) {
      const formData = new FormData();
      formData.append('ttl_sec', ttl_sec);
      supportApi.post(`ticket/${ticketId}/watch`, formData)
        .then(({ data }) => commit("updateTicketByData", data))
        .catch(() => { throw new Error('Unable to load ticket data'); });
    },
    async changeTicketStatus({ commit }, { ticketId, status }) {
      const response = await supportApi.post(`/ticket/${ticketId}/${status}`, {})
        .then(({ data }) => commit("updateTicketByData", data))
        .catch(async () => {
          throw new Error('failed loading user contacts');
        });

      return response;
    },
    async loadUserRestrictions({ commit }) {
      return supportApi.get("user/restrictions")
        .then(
          ({ data }) => {
            const { allowCreateTicket = true, allowReopenTicket = true, hasHiddenInn = false, isCjmEnabled = false, isWebUser = false } = data;
            commit("updateRestrictions", { allowReopenTicket, allowCreateTicket, hasHiddenInn, isCjmEnabled, isWebUser });
          }
        )
        .catch(() => {
          commit("updateRestrictions", { allowCreateTicket: true, allowReopenTicket: true, hasHiddenInn: false, isCjmEnabled: true, isWebUser: false });
        });
    },
    resetSolutionsHistory({ commit }) {
      commit("resetSolutionsHistory");
    },
    logSolutionHistory(
      { getters: { getProductSolutionHistoryTitles, getProductTitle, getProductSolutionById, isVipUser } },
      { product, solution_id = null, data = {}, path }
    ) {
      const navHistory = [
        ...(getProductTitle(product) ? [getProductTitle(product)] : []),
        ...getProductSolutionHistoryTitles(product),
        ...(solution_id !== null ? [getProductSolutionById({ product, solution_id }).title] : [])
      ];
      supportApi.post("analytics/solutions", {
        isVip: isVipUser,
        isNavEvent: solution_id === null,
        eventData: data,
        createdAt: new Date(),
        navHistory,
        appRoute: path.split("/").filter(p => p),
      });
    },
  }
}

export default support;
